export default function wrapStorage(storage) {
  /*
   * Wraps a storage to transparently handle JSON
   * no-op storage.
   */

  return {
    setItem: (key, data) => {
      const dataAsString = JSON.stringify(data);
      storage.setItem(key, dataAsString);
    },
    getItem: key => {
      let data = null;
      data = storage.getItem(key);
      if (data) {
        return JSON.parse(data);
      }
      return data;
    },
    removeItem: key => storage.removeItem(key),
    clear: storage.clear,
  };
}
