// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-use-before-define */
import { NextPageContext } from 'next';

import { OnlineCheckoutStatus } from '@zego/enums';
import { getCheckoutUrlWithProductParam } from '@zego/flows/onlineCheckout/redirects';
import { redirectToEstimates } from '@zego/flows/quickEstimates/redirects';
import { redirectToRefineQuote } from '@zego/flows/refineQuote/redirects';
import { EstimateState } from '@zego/generatedEnums';
import { isBrowser } from '@zego/utils';

export type PageHeaders = [
  number,
  {
    Location: { href: string };
  },
];

type featureFlag = boolean | undefined;

export const shouldRedirectToConnectProvider = (
  viewer: any,
  categoryCode: string,
  onlineCheckoutProgressStatus: OnlineCheckoutStatus | undefined,
  onlineCheckoutProgressStarted: boolean,
  currentUrl: string | undefined,
): boolean => {
  let redirect = false;

  const uberIntegrated = viewer?.employments?.[0]?.integratedAt;

  if (
    categoryCode === 'GB.PRIVATE_HIRE' &&
    !uberIntegrated &&
    onlineCheckoutProgressStarted &&
    onlineCheckoutProgressStatus === OnlineCheckoutStatus.STARTED &&
    !currentUrl?.includes('connect-provider')
  ) {
    redirect = true;
  }

  return redirect;
};

export const shouldRedirectToVanOwnGoodsSuccess = (
  onlineCheckoutProgressStatus: OnlineCheckoutStatus | undefined,
  onlineCheckoutProgressStarted: boolean,
  vanOwnGoodsFeatureEnabled: boolean,
): boolean => {
  return (
    vanOwnGoodsFeatureEnabled &&
    onlineCheckoutProgressStarted &&
    onlineCheckoutProgressStatus === OnlineCheckoutStatus.STARTED
  );
};

export interface FeatureFlags {
  vanOwnGoodsFeatureEnabled?: featureFlag;
  scooterMicroFrontendEnabled?: featureFlag;
}

export function redirectToRoute(
  viewer: any,
  categoryCode: string,
  actualUrlState: string | null,
  featureFlags: FeatureFlags,
  context: NextPageContext,
): PageHeaders | void {
  const { onlineCheckoutProgress } = viewer;

  const estimateState = viewer?.session?.estimateResult?.state;

  const baseUrl = getBaseUrlFromProductCategory(
    onlineCheckoutProgress?.categoryCode || categoryCode,
  );

  const onlineCheckoutProgressStarted =
    onlineCheckoutProgress?.categoryCode === categoryCode;

  const { source } = context.query;

  if (!estimateState) {
    if (source === 'OLA_UK') {
      return [302, { Location: { href: '/private-hire/ola/' } }];
    } else {
      return [302, { Location: { href: baseUrl } }];
    }
  }

  if (
    source === 'OLA_UK' &&
    estimateState === EstimateState.OPTION_CHOICE_NEEDED
  ) {
    return [302, { Location: { href: '/private-hire/ola/success' } }];
  }

  if (estimateState === EstimateState.SUPPORT_NEEDED) return;

  const currentUrl = isBrowser() ? context.asPath : context?.req?.url;

  if (
    shouldRedirectToConnectProvider(
      viewer,
      categoryCode,
      onlineCheckoutProgress?.status,
      onlineCheckoutProgressStarted,
      currentUrl,
    )
  ) {
    return [
      302,
      { Location: { href: `/connect-provider/?product=${categoryCode}` } },
    ];
  }

  if (
    shouldRedirectToVanOwnGoodsSuccess(
      onlineCheckoutProgress?.status,
      onlineCheckoutProgressStarted,
      featureFlags.vanOwnGoodsFeatureEnabled || false,
    )
  ) {
    return [302, { Location: { href: `/own-goods/success` } }];
  }

  if (!onlineCheckoutProgress) {
    return redirectToEstimates(
      baseUrl,
      estimateState,
      actualUrlState,
      featureFlags,
    );
  }

  if (
    onlineCheckoutProgress?.selectedPurchaseOption?.conversionAttribution?.creditedFeatureName.startsWith(
      'Aggregator',
    )
  ) {
    return [
      303,
      {
        Location: { href: getCheckoutUrlWithProductParam(categoryCode) },
      },
    ];
  }

  const { status } = onlineCheckoutProgress;

  if (onlineCheckoutProgressStarted) {
    return redirectToRefineQuote(
      baseUrl,
      status,
      onlineCheckoutProgress?.categoryCode || categoryCode,
    );
  }
}

export function getBaseUrlFromProductCategory(
  category: string | string[],
): string {
  switch (category) {
    case 'GB.VAN':
      return '/courier-van';
    case 'GB.VAN_OWN_GOODS':
      return '/own-goods';
    case 'GB.PRIVATE_HIRE':
    default:
      return '/private-hire';
  }
}
