import { NextPageContext } from 'next';

import { OnlineCheckoutCheck, OnlineCheckoutStatus } from '@zego/enums';
import { PageHeaders } from '@zego/flows/redirects';
import { getBaseUrlFromProductCategory } from '@zego/flows/redirects';
import { isBrowser } from '@zego/utils';

type Path = string | null;

interface SelectedPurchaseOption {
  conversionAttribution: { creditedFeatureName: string };
}

export interface OnlineCheckoutProgress {
  categoryCode: string;
  status: OnlineCheckoutStatus;
  currentCheck: OnlineCheckoutCheck;
  selectedPurchaseOption: SelectedPurchaseOption;
  onlineCheckoutEnabled?: boolean;
}

export function getCheckoutParamForCheckoutState(
  currentCheck: OnlineCheckoutCheck,
): Path {
  switch (currentCheck) {
    case OnlineCheckoutCheck.VERIFY_EMAIL_NEEDED:
      return 'verify-email';
    case OnlineCheckoutCheck.TERMS_OF_BUSINESS_NEEDED:
      return 'terms-of-business';
    case OnlineCheckoutCheck.DOCUMENT_UPLOAD_NEEDED:
      return 'upload-documents';
    case OnlineCheckoutCheck.LINK_UBER_NEEDED:
      return 'connect-uber';
    case OnlineCheckoutCheck.PAYMENT_NEEDED:
      return 'add-payment';
    case OnlineCheckoutCheck.DEPOSIT_PAYMENT_NEEDED:
      return 'add-deposit-payment';
    case OnlineCheckoutCheck.ZEGO_CHECKS_NEEDED:
    case OnlineCheckoutCheck.POLICY_LIVE:
      return 'complete';
    case OnlineCheckoutCheck.AFFORDABILITY_CHECK_FAILED:
      return 'affordability-check/decline';
    case OnlineCheckoutCheck.CREDIT_AGREEMENT_NEEDED:
      return 'credit-agreement/details';
    default:
      throw new Error(
        `Trying to get URL for invalid current check:
        ${currentCheck}`,
      );
  }
}

export function getUserHasSelectedFinalQuote(
  status: OnlineCheckoutStatus,
): boolean {
  return (
    status === OnlineCheckoutStatus.BOUND ||
    status === OnlineCheckoutStatus.QUOTE_SELECTED ||
    status === OnlineCheckoutStatus.PAID_FOR
  );
}

export function getCheckoutUrlWithProductParam(
  categoryCode: string,
  checkoutUrl?: string | null,
): string {
  if (!checkoutUrl) {
    return `/checkout/?product=${categoryCode}`;
  }
  return `/checkout/${checkoutUrl}/?product=${categoryCode}`;
}

export function navigateToCheckoutRoute(
  navigate: (
    url: { href: string },
    options: {
      query: { product: string };
    },
  ) => void,
  categoryCode = 'GB.PRIVATE_HIRE',
  checkoutParam?: string,
): void {
  const href = getCheckoutUrlWithProductParam(categoryCode, checkoutParam);
  return navigate({ href: href }, { query: { product: categoryCode } });
}

export function redirectToCheckout(
  {
    onlineCheckoutProgress,
  }: { onlineCheckoutProgress: OnlineCheckoutProgress },
  context: NextPageContext,
): PageHeaders | void {
  const actualUrl = isBrowser() ? context.asPath : context?.req?.url;
  const {
    currentCheck,
    onlineCheckoutEnabled,
    categoryCode,
    status,
  } = onlineCheckoutProgress;
  const checkoutParam = getCheckoutParamForCheckoutState(currentCheck);
  let targetUrl = getCheckoutUrlWithProductParam(categoryCode, checkoutParam);
  const base = getBaseUrlFromProductCategory(categoryCode);

  if (!onlineCheckoutEnabled) {
    targetUrl = `${base}/your-quote/success/`;
  }

  if (!getUserHasSelectedFinalQuote(status) || !categoryCode) {
    targetUrl = `${base}/`;
  }

  if (targetUrl && targetUrl !== actualUrl) {
    return [
      303,
      {
        Location: { href: targetUrl },
      },
    ];
  }
}
