import { isBrowser } from '@zego/utils';

import getCookieStorage from './getCookieStorage';
import isStorageSupported from './isStorageSupported';
import wrapStorage from './wrapStorage';

export const noopStorage = {
  setItem: () => {},
  getItem: () => undefined,
  removeItem: () => {},
  clear: () => {},
};

export default function getStorage(storageType) {
  if (!isBrowser()) {
    /*
     * On the server we don't want any kind of persistence, so return dummy
     * no-op storage.
     */
    return noopStorage;
  } else if (
    storageType === 'local' &&
    localStorage &&
    isStorageSupported(localStorage)
  ) {
    return wrapStorage(localStorage);
  } else if (
    storageType === 'session' &&
    sessionStorage &&
    isStorageSupported(sessionStorage)
  ) {
    return wrapStorage(sessionStorage);
  } else {
    // We can't use the real storage, so lets fake it with cookies
    return getCookieStorage(storageType);
  }
}
