import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import 'intersection-observer';
import 'focus-visible';

import utm from '@segment/utm-params';
import smoothscroll from 'smoothscroll-polyfill';

import getStorage from '@zego/getStorage';

typeof window !== 'undefined' && smoothscroll.polyfill();

if (!('ontouchstart' in document.documentElement)) {
  document.body.classList.add('no-touch');
}

if (typeof window !== 'undefined' && sessionStorage !== undefined) {
  const utmData = utm(window.location.search);

  if (utmData && Object.keys(utmData).length > 0) {
    const storage = getStorage('session');
    storage && storage.setItem('utmData', utm(window.location.search));
  }
}
