// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { OnlineCheckoutStatus } from '@zego/enums';
import { getCheckoutUrlWithProductParam } from '@zego/flows/onlineCheckout/redirects';

import { PageHeaders } from '../redirects';

type Path = string | null;

export function getUrlForOnlineCheckoutStatus(
  base: string,
  status: OnlineCheckoutStatus,
  categoryCode: string,
): Path {
  let path;
  switch (status) {
    case OnlineCheckoutStatus.STARTED:
      path = '/refine-quote/';
      break;
    case OnlineCheckoutStatus.QUOTE_REFINED:
    case OnlineCheckoutStatus.QUOTE_SELECTED:
      path = '/your-quote/';
      break;
    case OnlineCheckoutStatus.PAID_FOR:
    case OnlineCheckoutStatus.BOUND:
      return getCheckoutUrlWithProductParam(categoryCode);
    default:
      break;
  }
  if (!path) return null;

  return `${base}${path}`;
}

export function redirectToRefineQuote(
  base: string,
  status: OnlineCheckoutStatus,
  categoryCode: string,
): PageHeaders | void {
  const targetUrl = getUrlForOnlineCheckoutStatus(base, status, categoryCode);
  if (targetUrl) {
    return [
      303,
      {
        Location: { href: targetUrl },
      },
    ];
  }
}
