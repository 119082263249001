import { EstimateState } from '@zego/enums';
import { urlStateFromApiState } from '@zego/flows/quickEstimates/urlState';
import { FeatureFlags, PageHeaders } from '@zego/flows/redirects';

type Path = string | null;

export function getUrlForEstimateState(
  base: string,
  state: EstimateState,
): Path {
  const derivedUrlState = urlStateFromApiState(state);
  if (!derivedUrlState) return null;

  return `${base}/estimates/${derivedUrlState}/`;
}

export function redirectToEstimates(
  base: string,
  state: EstimateState,
  actualUrlState: string | null,
  featureFlags: FeatureFlags,
): PageHeaders | void {
  if (!actualUrlState) {
    return [302, { Location: { href: base } }];
  }

  const targetUrl = getUrlForEstimateState(base, state);
  const actualUrl = `${base}/estimates/${actualUrlState}/`;

  if (targetUrl && targetUrl !== actualUrl) {
    return [
      303,
      {
        Location: { href: targetUrl },
      },
    ];
  }
}
