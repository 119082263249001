const STORAGE_TEST_KEY = '@zego-storage-test';

export default function isStorageSupported(storage) {
  /*
   * Sometimes we have a storage (eg localStorage) but we can't write to it.
   * This is often because the browser is in a privacy mode. So to check
   * whether we can do things, we attempt some dummy writes.
   */
  try {
    storage.setItem(STORAGE_TEST_KEY, 'Z');
    storage.removeItem(STORAGE_TEST_KEY);
    return true;
  } catch {
    return false;
  }
}
