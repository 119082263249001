export const COUNTRY_MAP = {
  gb: 'en',
  ie: 'en',
  es: 'es',
  fr: 'fr',
  nl: 'nl',
};

export const LOCALE_MAP = {
  gb: 'en_GB',
  ie: 'en_IE',
  es: 'es_ES',
  fr: 'fr_FR',
  nl: 'nl_NL',
};

export const LOCALE_FORMAT = {
  IETF: 'IETF',
  ISO: 'ISO',
};
