import Cookies from 'js-cookie';

export const COOKIE_EXPIRES = 14;

export default function getCookieStorage(storageType) {
  /*
   * Returns a cookie-based storage that mimics the persistence model of
   * the provided storage type. Eg. a session cookie for session storage,
   * and a persisted cookie (arbitrarily set to 14 days) for local.
   */
  return {
    setItem: (key, value) => {
      Cookies.set(key, value, {
        expires: storageType === 'local' ? COOKIE_EXPIRES : undefined,
      });
    },
    getItem: key => {
      return Cookies.getJSON(key) || null;
    },
    removeItem: key => {
      Cookies.remove(key);
    },
    clear: () => {
      // noop
    },
  };
}
