// This file is generated using `yarn generate-types`
// Do not modify it directly

export enum Node {
  AbiVehicle = "AbiVehicle",
  ProductCategory = "ProductCategory",
  WorkProvider = "WorkProvider",
  Employment = "Employment",
  IntegratedShift = "IntegratedShift",
  Policy = "Policy",
  TermsAcceptance = "TermsAcceptance",
  RenewalQuote = "RenewalQuote",
  Country = "Country",
  ZendeskFaq = "ZendeskFaq",
  User = "User",
  Incident = "Incident",
  Notification = "Notification",
  UserFlexibleProduct = "UserFlexibleProduct",
  UserNonFlexibleProduct = "UserNonFlexibleProduct",
  PendingUserFlexibleProduct = "PendingUserFlexibleProduct",
  FixedTermQuote = "FixedTermQuote",
  SimpleTransaction = "SimpleTransaction",
  ProductPurchaseOption = "ProductPurchaseOption",
  ConversionAttribution = "ConversionAttribution",
  OnboardingConversation = "OnboardingConversation",
  BlogPost = "BlogPost",
  ReviewPrompt = "ReviewPrompt",
  ReferredPeople = "ReferredPeople",
  OnlineCheckoutProgress = "OnlineCheckoutProgress",
  Fleet = "Fleet",
  Company = "Company",
  FleetVehicle = "FleetVehicle",
  FleetPolicy = "FleetPolicy",
  Broker = "Broker",
  FleetVehicleAuthorisation = "FleetVehicleAuthorisation",
  FleetQuotation = "FleetQuotation",
  FleetInvoice = "FleetInvoice",
  FleetDriver = "FleetDriver",
  FleetDriverSelfOnboarding = "FleetDriverSelfOnboarding",
  FleetAvailableIntegration = "FleetAvailableIntegration",
  FleetSubscriptionQuote = "FleetSubscriptionQuote",
  Subscription = "Subscription",
  FleetClaimsHandler = "FleetClaimsHandler",
  CSVUploadTask = "CSVUploadTask",
  FlexibleQuote = "FlexibleQuote",
  FleetCalculatorTariff = "FleetCalculatorTariff",
  FleetCalculatorLead = "FleetCalculatorLead",
  FleetCalculatorProposal = "FleetCalculatorProposal",
  FleetCalculatorClaimSummary = "FleetCalculatorClaimSummary",
  Vehicle = "Vehicle",
  Individual = "Individual",
  UserBundledProduct = "UserBundledProduct",
  NonFlexibleProduct = "NonFlexibleProduct",
  BundledQuote = "BundledQuote",
  FlexibleProduct = "FlexibleProduct",
  ScooterVehicle = "ScooterVehicle",
  CarVehicle = "CarVehicle",
  VanVehicle = "VanVehicle",
  Person = "Person",
  SelfServeShift = "SelfServeShift",
  PendingUserFixedTermProduct = "PendingUserFixedTermProduct",
  PendingUserBundledProduct = "PendingUserBundledProduct",
  UnknownPendingUserProduct = "UnknownPendingUserProduct",
  ProductPurchaseIntent = "ProductPurchaseIntent",
  FleetProduct = "FleetProduct",
  AbaxDevice = "AbaxDevice",
};

export enum BaseEstimateResult {
  CarEstimateResult = "CarEstimateResult",
  ScooterEstimateResult = "ScooterEstimateResult",
  VanEstimateResult = "VanEstimateResult",
  VanOwnGoodsEstimateResult = "VanOwnGoodsEstimateResult",
  PrivateHireEstimateResult = "PrivateHireEstimateResult",
};

export enum EstimateState {
  CREATED = "CREATED",
  REJECTED = "REJECTED",
  REJECTED_WITH_LEAD = "REJECTED_WITH_LEAD",
  VEHICLE_DETAILS_NEEDED = "VEHICLE_DETAILS_NEEDED",
  LEAD_DETAILS_NEEDED = "LEAD_DETAILS_NEEDED",
  CALCULATING_OPTIONS = "CALCULATING_OPTIONS",
  OPTION_CHOICE_NEEDED = "OPTION_CHOICE_NEEDED",
  NO_OPTIONS_AVAILABLE = "NO_OPTIONS_AVAILABLE",
  USER_NEEDED = "USER_NEEDED",
  SUPPORT_NEEDED = "SUPPORT_NEEDED",
  COMPLETED = "COMPLETED",
  GDPR_CLEANED = "GDPR_CLEANED",
};

export enum EstimateRejectionReason {
  TOO_YOUNG = "TOO_YOUNG",
  TOO_OLD = "TOO_OLD",
  CANNOT_COVER_OUTCODE = "CANNOT_COVER_OUTCODE",
  CANNOT_COVER_VEHICLE = "CANNOT_COVER_VEHICLE",
};

export enum InsuranceProduct {
  NonFlexibleProduct = "NonFlexibleProduct",
  FlexibleProduct = "FlexibleProduct",
  FleetProduct = "FleetProduct",
};

export enum CoverType {
  FULLY_COMPREHENSIVE = "FULLY_COMPREHENSIVE",
  THIRD_PARTY_FT = "THIRD_PARTY_FT",
  FULL_RESPONSIBILITY = "FULL_RESPONSIBILITY",
  THIRD_PARTY_ONLY = "THIRD_PARTY_ONLY",
  COMPARABLE_TO_UNDERLYING_SDP = "COMPARABLE_TO_UNDERLYING_SDP",
  THIRD_PARTY_PERSONAL_ACCIDENT = "THIRD_PARTY_PERSONAL_ACCIDENT",
  THIRD_PARTY_PERSONAL_ACCIDENT_LEGAL_COVER = "THIRD_PARTY_PERSONAL_ACCIDENT_LEGAL_COVER",
  BREAKDOWN_COVER = "BREAKDOWN_COVER",
  LEI = "LEI",
  PERSONAL_ACCIDENT = "PERSONAL_ACCIDENT",
  OWN_DAMAGES = "OWN_DAMAGES",
};

export enum PageContentBlock {
  PageContentMarkdownBlock = "PageContentMarkdownBlock",
  PageContentCriteriaListBlock = "PageContentCriteriaListBlock",
  PageContentPricingBlock = "PageContentPricingBlock",
  PageContentSmallPrintBlock = "PageContentSmallPrintBlock",
  PageContentContinueButton = "PageContentContinueButton",
  PageContentContinueButtonWithValue = "PageContentContinueButtonWithValue",
  PageContentSkipButton = "PageContentSkipButton",
  PageContentSpacer = "PageContentSpacer",
  PageContentFlexDiscountPayload = "PageContentFlexDiscountPayload",
};

export enum PageContentButtonPayload {
  PageContentFlexDiscountPayload = "PageContentFlexDiscountPayload",
};

export enum ProductCategoryStatus {
  DEFAULT = "DEFAULT",
  NEW = "NEW",
  BETA = "BETA",
};

export enum Shift {
  IntegratedShift = "IntegratedShift",
  SelfServeShift = "SelfServeShift",
};

export enum InsuranceSubject {
  ScooterVehicle = "ScooterVehicle",
  CarVehicle = "CarVehicle",
  VanVehicle = "VanVehicle",
  Person = "Person",
};

export enum UserProduct {
  UserFlexibleProduct = "UserFlexibleProduct",
  UserNonFlexibleProduct = "UserNonFlexibleProduct",
  UserBundledProduct = "UserBundledProduct",
};

export enum UserProductCoverStatus {
  INACTIVE = "INACTIVE",
  UNKNOWN = "UNKNOWN",
  COVERED = "COVERED",
};

export enum DisableReason {
  DisableReasonFailedPayment = "DisableReasonFailedPayment",
};

export enum DocumentType {
  POLICY_CERTIFICATE = "POLICY_CERTIFICATE",
  WORK_PROVIDER_POLICY_CERTIFICATE = "WORK_PROVIDER_POLICY_CERTIFICATE",
  SCHEDULE = "SCHEDULE",
  COVER_NOTE = "COVER_NOTE",
  KEY_FACTS = "KEY_FACTS",
  POLICY_BOOKLET = "POLICY_BOOKLET",
  INVOICE = "INVOICE",
  LETTER_OF_ENROLMENT = "LETTER_OF_ENROLMENT",
  POLICY_INFORMATION = "POLICY_INFORMATION",
  IPID = "IPID",
  STATEMENT_OF_FACTS = "STATEMENT_OF_FACTS",
  FLEET_VEHICLE_CERTIFICATE = "FLEET_VEHICLE_CERTIFICATE",
  FLEET_VEHICLE_GREEN_CARD = "FLEET_VEHICLE_GREEN_CARD",
  FLEET_CERTIFICATE = "FLEET_CERTIFICATE",
  FLEET_BOOKLET = "FLEET_BOOKLET",
  FLEET_SCHEDULE = "FLEET_SCHEDULE",
  FLEET_IPID = "FLEET_IPID",
  FLEET_GENERIC_VEHICLE_CERTIFICATE = "FLEET_GENERIC_VEHICLE_CERTIFICATE",
  FLEET_STARTER_PACK = "FLEET_STARTER_PACK",
  DRIVER_AUTHORISATION = "DRIVER_AUTHORISATION",
};

export enum TermsAcceptanceType {
  POLICY_SUMMARY = "POLICY_SUMMARY",
  CANCELLATION_SUMMARY = "CANCELLATION_SUMMARY",
  RENEWAL_SUMMARY = "RENEWAL_SUMMARY",
  TERMS_OF_BUSINESS = "TERMS_OF_BUSINESS",
  DEMANDS_AND_NEEDS = "DEMANDS_AND_NEEDS",
};

export enum InsuranceQuote {
  RenewalQuote = "RenewalQuote",
  FixedTermQuote = "FixedTermQuote",
  FlexibleQuote = "FlexibleQuote",
  BundledQuote = "BundledQuote",
};

export enum PaymentOption {
  CardPaymentOption = "CardPaymentOption",
  BalancePaymentOption = "BalancePaymentOption",
  CreditPaymentOption = "CreditPaymentOption",
};

export enum WebsiteNavigationSection {
  MarketingProductWebsiteNavigationSection = "MarketingProductWebsiteNavigationSection",
  SimpleWebsiteNavigationSection = "SimpleWebsiteNavigationSection",
};

export enum MotorVehicle {
  ScooterVehicle = "ScooterVehicle",
  CarVehicle = "CarVehicle",
  VanVehicle = "VanVehicle",
};

export enum LinkingStatus {
  ATTEMPTING = "ATTEMPTING",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
};

export enum IntegrationMethod {
  IntegrationMethodOAuth = "IntegrationMethodOAuth",
  IntegrationMethodDocumentShare = "IntegrationMethodDocumentShare",
  IntegrationMethodWebHook = "IntegrationMethodWebHook",
  IntegrationMethodWorkProviderClient = "IntegrationMethodWorkProviderClient",
};

export enum ProductAddon {
  BreakdownCoverAddon = "BreakdownCoverAddon",
  GoodsInTransitAddon = "GoodsInTransitAddon",
  NcdProtectionAddon = "NcdProtectionAddon",
  PublicLiabilityAddon = "PublicLiabilityAddon",
  ReplacementVehicleAddon = "ReplacementVehicleAddon",
};

export enum ProductAddonBase {
  BreakdownCoverAddon = "BreakdownCoverAddon",
  GoodsInTransitAddon = "GoodsInTransitAddon",
  NcdProtectionAddon = "NcdProtectionAddon",
  PublicLiabilityAddon = "PublicLiabilityAddon",
  ReplacementVehicleAddon = "ReplacementVehicleAddon",
};

export enum AnnualOrMonthlyPremiumPriceAddon {
  BreakdownCoverAddon = "BreakdownCoverAddon",
  GoodsInTransitAddon = "GoodsInTransitAddon",
};

export enum PremiumPercentageAddon {
  NcdProtectionAddon = "NcdProtectionAddon",
};

export enum PremiumPriceAddon {
  PublicLiabilityAddon = "PublicLiabilityAddon",
};

export enum VehicleAddon {
  ReplacementVehicleAddon = "ReplacementVehicleAddon",
};

export enum ProductClassOfUse {
  HNR = "HNR",
  SDP = "SDP",
  PL = "PL",
  TAXI = "TAXI",
  SDP_TAXI = "SDP_TAXI",
  SDP_BUSINESS = "SDP_BUSINESS",
  SDP_HNR = "SDP_HNR",
  BILLING = "BILLING",
  LEI = "LEI",
  BREAKDOWN = "BREAKDOWN",
  FLEET = "FLEET",
  VEHICLE_REPLACEMENT = "VEHICLE_REPLACEMENT",
  GIT = "GIT",
  SDP_HNR_CLASS1 = "SDP_HNR_CLASS1",
  SDP_CLASS1 = "SDP_CLASS1",
};

export enum ProductSubjectType {
  CAR = "CAR",
  SCOOTER = "SCOOTER",
  CYCLIST = "CYCLIST",
  PERSON = "PERSON",
  VAN = "VAN",
  FLEET = "FLEET",
};

export enum EstimateResultSupportNeededReason {
  CONFLICTING_ACTIVE_PRODUCT = "CONFLICTING_ACTIVE_PRODUCT",
  ACCOUNT_BLOCKED = "ACCOUNT_BLOCKED",
};

export enum EstimateActions {
  UPDATE_DATA = "UPDATE_DATA",
  CHOOSE_OPTION = "CHOOSE_OPTION",
  PROVIDE_USER = "PROVIDE_USER",
};

export enum EstimateType {
  GB_SCOOTER = "GB_SCOOTER",
  GB_CAR = "GB_CAR",
  GB_VAN = "GB_VAN",
  GB_PRIVATE_HIRE = "GB_PRIVATE_HIRE",
  GB_VAN_OWN_GOODS = "GB_VAN_OWN_GOODS",
};

export enum DriversLicence {
  UKDriversLicence = "UKDriversLicence",
  ESDriversLicence = "ESDriversLicence",
  IEDriversLicence = "IEDriversLicence",
};

export enum IncidentType {
  ACCIDENT = "ACCIDENT",
  FIRE = "FIRE",
  THEFT = "THEFT",
  OTHER = "OTHER",
  GLASS = "GLASS",
};

export enum IncidentStatus {
  DRAFT = "DRAFT",
  SUBMITTED_TO_ZEGO = "SUBMITTED_TO_ZEGO",
  SENT_TO_HANDLER = "SENT_TO_HANDLER",
  UNABLE_TO_SEND = "UNABLE_TO_SEND",
};

export enum PolicyHolderTypeEnum {
  ONLY_PH = "ONLY_PH",
  PH_AND_OTHER = "PH_AND_OTHER",
  NOT_PH = "NOT_PH",
};

export enum UserPolicyTypeEnum {
  FLEX = "FLEX",
  SENSE = "SENSE",
  NO_SENSE = "NO_SENSE",
  NONE = "NONE",
};

export enum ReferralClaimPoints {
  ON_REGISTRATION = "ON_REGISTRATION",
  ON_SELF_ENABLED = "ON_SELF_ENABLED",
};

export enum ClassOfUseKind {
  DELIVERY = "DELIVERY",
};

export enum CardType {
  VISA = "VISA",
  MASTERCARD = "MASTERCARD",
  AMERICAN_EXPRESS = "AMERICAN_EXPRESS",
  DISCOVER = "DISCOVER",
  DINERS_CLUB = "DINERS_CLUB",
};

export enum ZegoPlatform {
  APP = "APP",
  WEBSITE = "WEBSITE",
  FLEET_PORTAL = "FLEET_PORTAL",
};

export enum UserProductHasFixedPolicy {
  UserNonFlexibleProduct = "UserNonFlexibleProduct",
  UserBundledProduct = "UserBundledProduct",
};

export enum PendingUserProduct {
  PendingUserFlexibleProduct = "PendingUserFlexibleProduct",
  PendingUserFixedTermProduct = "PendingUserFixedTermProduct",
  PendingUserBundledProduct = "PendingUserBundledProduct",
  UnknownPendingUserProduct = "UnknownPendingUserProduct",
};

export enum BaseSelfEnabledChecks {
  FlexibleSelfEnablingStatus = "FlexibleSelfEnablingStatus",
  SimpleSelfEnablingStatus = "SimpleSelfEnablingStatus",
  FixedTermSelfEnablingStatus = "FixedTermSelfEnablingStatus",
  BundledSelfEnablingStatus = "BundledSelfEnablingStatus",
};

export enum SelfEnablingStaffCheckStatus {
  PENDING = "PENDING",
  PASSED = "PASSED",
  FAILED = "FAILED",
};

export enum SelfEnablingChecks {
  ZEGO_CHECKS = "ZEGO_CHECKS",
  AGREED_TERMS = "AGREED_TERMS",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  SETUP_PAYMENT = "SETUP_PAYMENT",
  ACCEPT_POLICY_SUMMARY = "ACCEPT_POLICY_SUMMARY",
  ACCEPT_CANCELLATION_SUMMARY = "ACCEPT_CANCELLATION_SUMMARY",
  LOCATION_PERMISSION = "LOCATION_PERMISSION",
  OAUTH_INTEGRATION = "OAUTH_INTEGRATION",
};

export enum PaymentPlan {
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY",
  BALANCE = "BALANCE",
};

export enum SimpleTransactionType {
  POLICY = "POLICY",
  REFUND = "REFUND",
  ADMIN = "ADMIN",
  CREDIT = "CREDIT",
  REFERRAL = "REFERRAL",
  PAYMENT = "PAYMENT",
  CANCELLATION = "CANCELLATION",
  GOODWILL_USER = "GOODWILL_USER",
  GOODWILL_ZEGO = "GOODWILL_ZEGO",
  BALANCE_TRANSFER = "BALANCE_TRANSFER",
  CASHBACK = "CASHBACK",
  PROMO = "PROMO",
  DISCOUNT = "DISCOUNT",
  CORRECTION = "CORRECTION",
  WORK_PROVIDER = "WORK_PROVIDER",
  FLEX_SETUP_FEE = "FLEX_SETUP_FEE",
  UNKNOWN = "UNKNOWN",
};

export enum ProductPurchaseOptionAddon {
  ProductPurchaseOptionDiscountAddon = "ProductPurchaseOptionDiscountAddon",
};

export enum OnboardingConversationStatus {
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED_SUCCESS = "FINISHED_SUCCESS",
  FINISHED_FAILED = "FINISHED_FAILED",
  ABANDONED = "ABANDONED",
};

export enum ReferralClaimStatus {
  EXPIRED = "EXPIRED",
  SIGNED_UP = "SIGNED_UP",
  REDEEMED = "REDEEMED",
};

export enum AvailableFeatures {
  WEBSITE_CONTACT_FORM = "WEBSITE_CONTACT_FORM",
  WEBSITE_WHATSAPP = "WEBSITE_WHATSAPP",
  PRIVATE_HIRE_SENSE_CHECKOUT_ENABLED = "PRIVATE_HIRE_SENSE_CHECKOUT_ENABLED",
  UBER_INSURANCE_VERIFICATION = "UBER_INSURANCE_VERIFICATION",
  SALESFORCE_INTEGRATION = "SALESFORCE_INTEGRATION",
  FLEET_PORTAL_ADD_DRIVER_ENABLED = "FLEET_PORTAL_ADD_DRIVER_ENABLED",
  FLEET_PORTAL_DOWNLOAD_GREEN_CARDS_ENABLED = "FLEET_PORTAL_DOWNLOAD_GREEN_CARDS_ENABLED",
  FLEET_PORTAL_DIRECT_DEBIT_ENABLED = "FLEET_PORTAL_DIRECT_DEBIT_ENABLED",
  LINK_OAUTH_WORK_PROVIDERS_ENABLED = "LINK_OAUTH_WORK_PROVIDERS_ENABLED",
  FLEET_PORTAL_BULK_VEHICLE_UPLOADER_ENABLED = "FLEET_PORTAL_BULK_VEHICLE_UPLOADER_ENABLED",
  FLEET_PORTAL_CLAIMS_DATA_ENABLED = "FLEET_PORTAL_CLAIMS_DATA_ENABLED",
  FLEET_PORTAL_DESELECT_POLICIES_ENABLED = "FLEET_PORTAL_DESELECT_POLICIES_ENABLED",
};

export enum UploadDocument {
  UserUploadDocument = "UserUploadDocument",
  VehicleUploadDocument = "VehicleUploadDocument",
};

export enum UploadDocumentSaleType {
  PRE_SALE = "PRE_SALE",
  POST_SALE = "POST_SALE",
};

export enum OnlineCheckoutStatus {
  STARTED = "STARTED",
  PERSONAL_DETAILS_FILLED = "PERSONAL_DETAILS_FILLED",
  QUOTE_REFINED = "QUOTE_REFINED",
  QUOTE_SELECTED = "QUOTE_SELECTED",
  CHECKS_COMPLETED = "CHECKS_COMPLETED",
  PAID_FOR = "PAID_FOR",
  BOUND = "BOUND",
};

export enum OnlineCheckoutCheck {
  VERIFY_EMAIL_NEEDED = "VERIFY_EMAIL_NEEDED",
  TERMS_OF_BUSINESS_NEEDED = "TERMS_OF_BUSINESS_NEEDED",
  AFFORDABILITY_CHECK_FAILED = "AFFORDABILITY_CHECK_FAILED",
  POLICY_SUMMARY = "POLICY_SUMMARY",
  DOCUMENT_UPLOAD_NEEDED = "DOCUMENT_UPLOAD_NEEDED",
  LINK_UBER_NEEDED = "LINK_UBER_NEEDED",
  LINK_UBER_SKIPPED = "LINK_UBER_SKIPPED",
  PAYMENT_NEEDED = "PAYMENT_NEEDED",
  DEPOSIT_PAYMENT_NEEDED = "DEPOSIT_PAYMENT_NEEDED",
  CREDIT_AGREEMENT_NEEDED = "CREDIT_AGREEMENT_NEEDED",
  CREDIT_AGREEMENT_DECLINED = "CREDIT_AGREEMENT_DECLINED",
  ZEGO_CHECKS_NEEDED = "ZEGO_CHECKS_NEEDED",
  POLICY_LIVE = "POLICY_LIVE",
  INVALID_STATE = "INVALID_STATE",
};

export enum StripePaymentIntentStatus {
  REQUIRES_PAYMENT_METHOD = "REQUIRES_PAYMENT_METHOD",
  REQUIRES_CONFIRMATION = "REQUIRES_CONFIRMATION",
  REQUIRES_ACTION = "REQUIRES_ACTION",
  REQUIRES_CAPTURE = "REQUIRES_CAPTURE",
  PROCESSING = "PROCESSING",
  CANCELED = "CANCELED",
  SUCCEEDED = "SUCCEEDED",
};

export enum OnlineCheckoutPaymentOption {
  UP_FRONT = "UP_FRONT",
  PCL_MONTHLY_CREDIT = "PCL_MONTHLY_CREDIT",
};

export enum DistanceUnit {
  KM = "KM",
  MI = "MI",
};

export enum FuelType {
  PETROL = "PETROL",
  DIESEL = "DIESEL",
  ELECTRIC = "ELECTRIC",
  HYBRID = "HYBRID",
  GAS_BI_FUEL = "GAS_BI_FUEL",
};

export enum GarageType {
  INDIVIDUAL = "INDIVIDUAL",
  COMMUNAL = "COMMUNAL",
  COMMUNAL_WITH_SURVEILLANCE = "COMMUNAL_WITH_SURVEILLANCE",
};

export enum VehicleType {
  SCOOTER = "SCOOTER",
  CAR = "CAR",
  VAN = "VAN",
  ELECTRIC_BIKE = "ELECTRIC_BIKE",
  ELECTRIC_KICK_SCOOTER = "ELECTRIC_KICK_SCOOTER",
  HGV = "HGV",
  TRAILER = "TRAILER",
};

export enum FleetPolicyStatus {
  COVERED = "COVERED",
  COVER_PENDING = "COVER_PENDING",
  NOT_COVERED = "NOT_COVERED",
};

export enum FleetVehicleCoverStatus {
  COVERED = "COVERED",
  COVER_PENDING = "COVER_PENDING",
  REMOVED = "REMOVED",
  PROCESSING = "PROCESSING",
  NOT_COVERED = "NOT_COVERED",
};

export enum RequestStatus {
  DECLINED = "DECLINED",
  APPROVED = "APPROVED",
  MANUAL_PRICING_REQUIRED = "MANUAL_PRICING_REQUIRED",
  ACCEPTED = "ACCEPTED",
};

export enum FleetVehicleIntegrationDevice {
  AbaxDevice = "AbaxDevice",
};

export enum IntegrationDeviceStatus {
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
};

export enum IntegrationDeviceHealth {
  HEALTHY = "HEALTHY",
  DEGRADED = "DEGRADED",
  UNHEALTHY = "UNHEALTHY",
};

export enum IntegrationDeviceStatusFilter {
  HEALTHY = "HEALTHY",
  DEGRADED = "DEGRADED",
  UNHEALTHY = "UNHEALTHY",
  UNREGISTERED = "UNREGISTERED",
};

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
};

export enum FleetVehicleFieldOrderFieldName {
  DEVICE_STATUS = "DEVICE_STATUS",
  REGISTRATION_NUMBER = "REGISTRATION_NUMBER",
  COVER_START = "COVER_START",
  ADDED_AT = "ADDED_AT",
};

export enum FleetDriverApprovalStatus {
  APPROVED = "APPROVED",
  MANUALLY_APPROVED = "MANUALLY_APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  INCOMPLETE = "INCOMPLETE",
  CHECKS_ERRORED = "CHECKS_ERRORED",
};

export enum FleetDriverFieldOrderFieldName {
  DOB = "DOB",
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  EMAIL = "EMAIL",
};

export enum StripeMandateStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
};

export enum StripeMandateType {
  SINGLE_USE = "SINGLE_USE",
  MULTI_USE = "MULTI_USE",
};

export enum FleetWebhookEventKind {
  FLEET_DRIVER_STATUS_CHANGED = "FLEET_DRIVER_STATUS_CHANGED",
  FLEET_AUTHORISATION_CANCELLED = "FLEET_AUTHORISATION_CANCELLED",
  FLEET_AUTHORISATION_PRICE_CHANGED = "FLEET_AUTHORISATION_PRICE_CHANGED",
};

export enum QuoteStatus {
  DECLINED = "DECLINED",
  APPROVED = "APPROVED",
  MANUAL_PRICING_REQUIRED = "MANUAL_PRICING_REQUIRED",
  ACCEPTED = "ACCEPTED",
  QUOTED = "QUOTED",
};

export enum FleetSubscriptionQuoteFieldOrderFieldName {
  DATE_CREATED = "DATE_CREATED",
};

export enum SubscriptionFieldOrderFieldName {
  START_TIME = "START_TIME",
};

export enum FleetClaimsHandlerContactType {
  GENERAL_ENQUIRIES = "GENERAL_ENQUIRIES",
  ROAD_ASSISTANCE = "ROAD_ASSISTANCE",
  NEW_CLAIMS = "NEW_CLAIMS",
  EXISTING_CLAIMS = "EXISTING_CLAIMS",
};

export enum FleetVehicleIntegrationDeviceStatus {
  HEALTHY = "HEALTHY",
  DEGRADED = "DEGRADED",
  UNHEALTHY = "UNHEALTHY",
  DEACTIVATED = "DEACTIVATED",
  NOT_REGISTERED = "NOT_REGISTERED",
};

export enum VehicleFieldOrderFieldName {
  REGISTRATION_NUMBER = "REGISTRATION_NUMBER",
  MAKE = "MAKE",
};

export enum FleetDriverCoverStatus {
  APPROVED = "APPROVED",
  MANUALLY_APPROVED = "MANUALLY_APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  INCOMPLETE = "INCOMPLETE",
  CHECKS_ERRORED = "CHECKS_ERRORED",
};

export enum FleetDriverLicenceType {
  FULL = "FULL",
  PROVISIONAL = "PROVISIONAL",
  FULL_EU = "FULL_EU",
};

export enum DriverFieldOrderFieldName {
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  EMAIL = "EMAIL",
};

export enum UploadTaskStatus {
  STARTED = "STARTED",
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  FAILURE = "FAILURE",
  UNEXPECTED_FAIL = "UNEXPECTED_FAIL",
};

export enum Variation {
  BooleanVariation = "BooleanVariation",
  StringVariation = "StringVariation",
};

export enum AbandonConversationPayload {
  AuthenticationRequired = "AuthenticationRequired",
  AbandonConversationError = "AbandonConversationError",
  AbandonConversationSuccess = "AbandonConversationSuccess",
};

export enum AddInternalReviewPayload {
  AuthenticationRequired = "AuthenticationRequired",
  AddInternalReviewError = "AddInternalReviewError",
  AddInternalReviewSuccess = "AddInternalReviewSuccess",
};

export enum AddCardPayload {
  AuthenticationRequired = "AuthenticationRequired",
  AddCardError = "AddCardError",
  AddCardSuccess = "AddCardSuccess",
};

export enum AddCardAndCreditPayload {
  AuthenticationRequired = "AuthenticationRequired",
  AddCardAndCreditError = "AddCardAndCreditError",
  AddCardAndCreditSuccess = "AddCardAndCreditSuccess",
};

export enum AddPaymentMethodWithCreditPayload {
  PaymentMethodWithCreditError = "PaymentMethodWithCreditError",
  PaymentMethodWithCreditSuccess = "PaymentMethodWithCreditSuccess",
  PaymentMethodWithCreditRequiresAction = "PaymentMethodWithCreditRequiresAction",
};

export enum AddCreditPayload {
  AuthenticationRequired = "AuthenticationRequired",
  AddCreditError = "AddCreditError",
  AddCreditSuccess = "AddCreditSuccess",
};

export enum AddDeviceTokenPayload {
  AuthenticationRequired = "AuthenticationRequired",
  AddDeviceTokenSuccess = "AddDeviceTokenSuccess",
};

export enum AddUKBankAccountPayload {
  AuthenticationRequired = "AuthenticationRequired",
  AddUKBankAccountError = "AddUKBankAccountError",
  AddUKBankAccountSuccess = "AddUKBankAccountSuccess",
};

export enum AddPaymentMethodPayload {
  AddPaymentMethodError = "AddPaymentMethodError",
  AddPaymentMethodSuccess = "AddPaymentMethodSuccess",
};

export enum AcceptPolicySummaryPayload {
  AuthenticationRequired = "AuthenticationRequired",
  AcceptPolicySummaryError = "AcceptPolicySummaryError",
  AcceptPolicySummarySuccess = "AcceptPolicySummarySuccess",
};

export enum AcceptTermsPayload {
  AuthenticationRequired = "AuthenticationRequired",
  AcceptTermsError = "AcceptTermsError",
  AcceptTermsSuccess = "AcceptTermsSuccess",
};

export enum AgreeTermsPayload {
  AgreeTermsError = "AgreeTermsError",
  AgreeTermsSuccess = "AgreeTermsSuccess",
  AuthenticationRequired = "AuthenticationRequired",
};

export enum AgreeWorkProviderLinkPayload {
  AgreeWorkProviderLinkError = "AgreeWorkProviderLinkError",
  AgreeWorkProviderLinkSuccess = "AgreeWorkProviderLinkSuccess",
  AuthenticationRequired = "AuthenticationRequired",
};

export enum AggregatorAuthenticateUserPayload {
  AuthenticateUserInvalidInput = "AuthenticateUserInvalidInput",
  AggregatorAuthenticateUserSuccess = "AggregatorAuthenticateUserSuccess",
  GetQuoteError = "GetQuoteError",
};

export enum AuthenticateUserFieldName {
  EMAIL = "EMAIL",
  PASSWORD = "PASSWORD",
};

export enum AggregatorRegisterUserPayload {
  RegisterUserInvalidInput = "RegisterUserInvalidInput",
  GetQuoteError = "GetQuoteError",
  AggregatorRegisterUserSuccess = "AggregatorRegisterUserSuccess",
};

export enum RegisterUserFieldName {
  GIVEN_NAMES = "GIVEN_NAMES",
  LAST_NAME = "LAST_NAME",
  PHONE_NUMBER = "PHONE_NUMBER",
  EMAIL = "EMAIL",
  PASSWORD = "PASSWORD",
  COUNTRY = "COUNTRY",
  REFERRAL_CODE = "REFERRAL_CODE",
};

export enum AuthenticateUserPayload {
  AuthenticateUserInvalidInput = "AuthenticateUserInvalidInput",
  AuthenticateUserSuccess = "AuthenticateUserSuccess",
};

export enum LoginPayload {
  LoginInvalidInput = "LoginInvalidInput",
  LoginSuccess = "LoginSuccess",
};

export enum LoginFieldName {
  EMAIL = "EMAIL",
  PASSWORD = "PASSWORD",
  RECAPTCHA = "RECAPTCHA",
};

export enum CreateQuotePayload {
  CreateQuoteSuccess = "CreateQuoteSuccess",
  GetQuoteError = "GetQuoteError",
  InvalidProductCategoryError = "InvalidProductCategoryError",
};

export enum NCDProtectionLevels {
  ZERO_YEARS = "ZERO_YEARS",
  FIVE_YEARS = "FIVE_YEARS",
};

export enum PublicLiabilityCoverValues {
  PL_5M = "PL_5M",
  PL_10M = "PL_10M",
};

export enum BindFixedTermQuotePayload {
  BindFixedTermQuoteRequiresAction = "BindFixedTermQuoteRequiresAction",
  AuthenticationRequired = "AuthenticationRequired",
  BindFixedTermQuoteError = "BindFixedTermQuoteError",
  BindFixedTermQuoteSuccess = "BindFixedTermQuoteSuccess",
};

export enum ChangeEmailAddressPayload {
  ChangeEmailAddressInvalidInput = "ChangeEmailAddressInvalidInput",
  ChangeEmailAddressSuccess = "ChangeEmailAddressSuccess",
  AuthenticationRequired = "AuthenticationRequired",
};

export enum ChangeEmailAddressFieldName {
  EMAIL = "EMAIL",
};

export enum ChangePasswordPayload {
  AuthenticationRequired = "AuthenticationRequired",
  ChangePasswordInvalidInput = "ChangePasswordInvalidInput",
  ChangePasswordSuccess = "ChangePasswordSuccess",
};

export enum ChangePasswordFieldName {
  PASSWORD = "PASSWORD",
};

export enum CompleteOnboardingConversationPayload {
  AuthenticationRequired = "AuthenticationRequired",
  CompleteOnboardingConversationError = "CompleteOnboardingConversationError",
  CompleteOnboardingConversationSuccess = "CompleteOnboardingConversationSuccess",
};

export enum CompleteProductPurchaseOptionsPayload {
  AuthenticationRequired = "AuthenticationRequired",
  CompleteProductPurchaseOptionsError = "CompleteProductPurchaseOptionsError",
  CompleteProductPurchaseOptionsSuccess = "CompleteProductPurchaseOptionsSuccess",
};

export enum CompleteReviewPromptPayload {
  AuthenticationRequired = "AuthenticationRequired",
  CompleteReviewPromptError = "CompleteReviewPromptError",
  CompleteReviewPromptSuccess = "CompleteReviewPromptSuccess",
};

export enum ProgressAuthenticatedPaymentRequestPayload {
  AuthenticationRequired = "AuthenticationRequired",
  ProgressAuthenticatedPaymentRequestError = "ProgressAuthenticatedPaymentRequestError",
  ProgressAuthenticatedPaymentRequestSuccess = "ProgressAuthenticatedPaymentRequestSuccess",
};

export enum ContactUsPayload {
  ContactUsInvalidInput = "ContactUsInvalidInput",
  ContactUsSuccess = "ContactUsSuccess",
};

export enum ContactUsFieldName {
  NAME = "NAME",
  PHONE_NUMBER = "PHONE_NUMBER",
  EMAIL = "EMAIL",
  MESSAGE = "MESSAGE",
};

export enum DeleteDeviceTokenPayload {
  AuthenticationRequired = "AuthenticationRequired",
  DeleteDeviceTokenError = "DeleteDeviceTokenError",
  DeleteDeviceTokenSuccess = "DeleteDeviceTokenSuccess",
};

export enum DeletePaymentCardPayload {
  AuthenticationRequired = "AuthenticationRequired",
  DeletePaymentCardSuccess = "DeletePaymentCardSuccess",
  DeletePaymentCardError = "DeletePaymentCardError",
};

export enum DismissReviewPromptPayload {
  AuthenticationRequired = "AuthenticationRequired",
  DismissReviewPromptError = "DismissReviewPromptError",
  DismissReviewPromptSuccess = "DismissReviewPromptSuccess",
};

export enum EmailUserMagicLinkPayload {
  AuthenticationRequired = "AuthenticationRequired",
  EmailUserMagicLinkSuccess = "EmailUserMagicLinkSuccess",
  EmailUserMagicLinkError = "EmailUserMagicLinkError",
};

export enum MagicLinkPlatformType {
  ONLINE_CHECKOUT = "ONLINE_CHECKOUT",
  MOBILE_APP = "MOBILE_APP",
  PH_TELE = "PH_TELE",
  SENSE = "SENSE",
  FLEET_PORTAL = "FLEET_PORTAL",
};

export enum RequestEmailMagicLinkPayload {
  RequestEmailMagicLinkSuccess = "RequestEmailMagicLinkSuccess",
  RequestEmailMagicLinkError = "RequestEmailMagicLinkError",
};

export enum EnablePendingUserFlexibleProductPayload {
  AuthenticationRequired = "AuthenticationRequired",
  EnablePendingUserFlexibleProductSuccess = "EnablePendingUserFlexibleProductSuccess",
  EnablePendingUserFlexibleProductError = "EnablePendingUserFlexibleProductError",
};

export enum GetFleetSubscriptionQuotePayload {
  GetFleetSubscriptionQuoteError = "GetFleetSubscriptionQuoteError",
  GetFleetSubscriptionQuoteSuccess = "GetFleetSubscriptionQuoteSuccess",
  GetFleetSubscriptionQuoteDecline = "GetFleetSubscriptionQuoteDecline",
};

export enum GetMagicLinkTokenPayload {
  GetMagicLinkTokenSuccess = "GetMagicLinkTokenSuccess",
  GetMagicLinkTokenExpiredError = "GetMagicLinkTokenExpiredError",
  GetMagicLinkTokenNoSensePolicyError = "GetMagicLinkTokenNoSensePolicyError",
  GetMagicLinkTokenInvalidCodeError = "GetMagicLinkTokenInvalidCodeError",
};

export enum GetMagicLinkTokenError {
  GetMagicLinkTokenExpiredError = "GetMagicLinkTokenExpiredError",
  GetMagicLinkTokenNoSensePolicyError = "GetMagicLinkTokenNoSensePolicyError",
  GetMagicLinkTokenInvalidCodeError = "GetMagicLinkTokenInvalidCodeError",
};

export enum GetNonFlexibleQuotePayload {
  AuthenticationRequired = "AuthenticationRequired",
  GetNonFlexibleQuoteError = "GetNonFlexibleQuoteError",
  GetNonFlexibleQuoteSuccess = "GetNonFlexibleQuoteSuccess",
};

export enum GetPurchaseOptionsPayload {
  GetPurchaseOptionsError = "GetPurchaseOptionsError",
  GetPurchaseOptionsSuccess = "GetPurchaseOptionsSuccess",
};

export enum GetOPLQuotePayload {
  GetOPLQuotesError = "GetOPLQuotesError",
  GetOPLQuotesSuccess = "GetOPLQuotesSuccess",
};

export enum MarkNotificationAsReadPayload {
  AuthenticationRequired = "AuthenticationRequired",
  MarkNotificationAsReadError = "MarkNotificationAsReadError",
  MarkNotificationAsReadSuccess = "MarkNotificationAsReadSuccess",
};

export enum RegisterUserPayload {
  RegisterUserInvalidInput = "RegisterUserInvalidInput",
  RegisterUserSuccess = "RegisterUserSuccess",
};

export enum ResendEmailVerificationPayload {
  AuthenticationRequired = "AuthenticationRequired",
  ResendEmailVerificationError = "ResendEmailVerificationError",
  ResendEmailVerificationSuccess = "ResendEmailVerificationSuccess",
};

export enum RevokeCurrentTokenPayload {
  AuthenticationRequired = "AuthenticationRequired",
  RevokeCurrentTokenSuccess = "RevokeCurrentTokenSuccess",
};

export enum SendPasswordResetEmailPayload {
  SendPasswordResetEmailInvalidInput = "SendPasswordResetEmailInvalidInput",
  SendPasswordResetEmailSuccess = "SendPasswordResetEmailSuccess",
};

export enum SendPasswordResetEmailFieldName {
  EMAIL = "EMAIL",
};

export enum SendVanOwnGoodsInterestedEmailAddressPayload {
  SendVanOwnGoodsInterestedEmailAddressSuccess = "SendVanOwnGoodsInterestedEmailAddressSuccess",
  SendVanOwnGoodsInterestedEmailAddressError = "SendVanOwnGoodsInterestedEmailAddressError",
};

export enum SetAutoTopUpAmountPayload {
  AuthenticationRequired = "AuthenticationRequired",
  SetAutoTopUpAmountError = "SetAutoTopUpAmountError",
  SetAutoTopUpAmountSuccess = "SetAutoTopUpAmountSuccess",
};

export enum SetDefaultPaymentCardPayload {
  AuthenticationRequired = "AuthenticationRequired",
  SetDefaultPaymentCardSuccess = "SetDefaultPaymentCardSuccess",
  SetDefaultPaymentCardError = "SetDefaultPaymentCardError",
};

export enum SetInitialUTMParamsPayload {
  AuthenticationRequired = "AuthenticationRequired",
  SetInitialUTMParamsError = "SetInitialUTMParamsError",
  SetInitialUTMParamsSuccess = "SetInitialUTMParamsSuccess",
};

export enum StripeSetupIntentStatus {
  REQUIRES_PAYMENT_METHOD = "REQUIRES_PAYMENT_METHOD",
  REQUIRES_CONFIRMATION = "REQUIRES_CONFIRMATION",
  REQUIRES_ACTION = "REQUIRES_ACTION",
  PROCESSING = "PROCESSING",
  CANCELED = "CANCELED",
  SUCCEEDED = "SUCCEEDED",
};

export enum TopUpPayload {
  AuthenticationRequired = "AuthenticationRequired",
  TopUpError = "TopUpError",
  TopUpSuccess = "TopUpSuccess",
};

export enum OnSessionTopUp {
  OnSessionTopUpError = "OnSessionTopUpError",
  OnSessionTopUpRequiresAction = "OnSessionTopUpRequiresAction",
  OnSessionTopUpSuccess = "OnSessionTopUpSuccess",
};

export enum UpdateAnonymousUserPayload {
  UpdateAnonymousUserInvalidInput = "UpdateAnonymousUserInvalidInput",
  UpdateAnonymousUserSuccess = "UpdateAnonymousUserSuccess",
  AuthenticationRequired = "AuthenticationRequired",
};

export enum UpdateAnonymousUserFieldName {
  GIVEN_NAMES = "GIVEN_NAMES",
  LAST_NAME = "LAST_NAME",
  PHONE_NUMBER = "PHONE_NUMBER",
  EMAIL = "EMAIL",
  PASSWORD = "PASSWORD",
  COUNTRY = "COUNTRY",
  DATE_OF_BIRTH = "DATE_OF_BIRTH",
  ADDRESS_LINE_1 = "ADDRESS_LINE_1",
  ADDRESS_LINE_2 = "ADDRESS_LINE_2",
  CITY = "CITY",
  POSTCODE = "POSTCODE",
};

export enum UpdateCommunicationsPayload {
  AuthenticationRequired = "AuthenticationRequired",
  UpdateCommunicationsError = "UpdateCommunicationsError",
  UpdateCommunicationsSuccess = "UpdateCommunicationsSuccess",
};

export enum UpdateAutoRenewalPayload {
  AuthenticationRequired = "AuthenticationRequired",
  UpdateAutoRenewalError = "UpdateAutoRenewalError",
  UpdateAutoRenewalSuccess = "UpdateAutoRenewalSuccess",
};

export enum ReferralCodeAddPayload {
  ReferralCodeAddError = "ReferralCodeAddError",
  ReferralCodeAddSuccess = "ReferralCodeAddSuccess",
};

export enum ValidateReferralCodePayload {
  ReferralCodeValidationError = "ReferralCodeValidationError",
  ReferralCodeValid = "ReferralCodeValid",
};

export enum UpdateUberUserDataPayload {
  AuthenticationRequired = "AuthenticationRequired",
  UpdateUberUserDataSuccess = "UpdateUberUserDataSuccess",
};

export enum PrivateHireRefinePayload {
  AuthenticationRequired = "AuthenticationRequired",
  PrivateHireRefineUpdate = "PrivateHireRefineUpdate",
  PrivateHireRefineQuote = "PrivateHireRefineQuote",
  PrivateHireRefineError = "PrivateHireRefineError",
};

export enum FormWidget {
  FormCurrencyWidget = "FormCurrencyWidget",
  FormDateWidget = "FormDateWidget",
  FormSearchListSelectWidget = "FormSearchListSelectWidget",
  FormListSelectWidget = "FormListSelectWidget",
  FormChoiceWidget = "FormChoiceWidget",
  FormRegistrationWidget = "FormRegistrationWidget",
  FormAddressWidget = "FormAddressWidget",
  FormDrivingLicenceWidget = "FormDrivingLicenceWidget",
  FormIntegerWidget = "FormIntegerWidget",
  FormTextWidget = "FormTextWidget",
  FormMultipleSelectWidget = "FormMultipleSelectWidget",
};

export enum RefineQuoteIconType {
  CLAIMS = "CLAIMS",
};

export enum RefineQuoteWarningMessageType {
  CANNOT_INSURE = "CANNOT_INSURE",
  CALL_US = "CALL_US",
  INFORMATION = "INFORMATION",
  ERROR = "ERROR",
};

export enum PrivateHireTestGroup {
  PER_SECTION = "PER_SECTION",
  PER_FIELD = "PER_FIELD",
};

export enum RefineQuoteSubmitType {
  UPDATE = "UPDATE",
  PARTIAL_SAVE = "PARTIAL_SAVE",
  FINAL_SUBMIT = "FINAL_SUBMIT",
};

export enum VanRefinePayload {
  AuthenticationRequired = "AuthenticationRequired",
  VanRefineUpdate = "VanRefineUpdate",
  VanRefineQuote = "VanRefineQuote",
  VanRefineError = "VanRefineError",
};

export enum UpdateVehicleTelematicsDevicePayload {
  UpdateVehicleTelematicsDeviceSuccess = "UpdateVehicleTelematicsDeviceSuccess",
  UpdateVehicleTelematicsDeviceError = "UpdateVehicleTelematicsDeviceError",
};

export enum TelematicsDeviceOptions {
  OBD = "OBD",
  APP = "APP",
};

export enum ProvidePaymentConsentPayload {
  ProvidePaymentConsentSuccess = "ProvidePaymentConsentSuccess",
  ProvidePaymentConsentError = "ProvidePaymentConsentError",
  AuthenticationRequired = "AuthenticationRequired",
};

export enum VehiclePayload {
  AuthenticationRequired = "AuthenticationRequired",
  VehicleLookupSuccess = "VehicleLookupSuccess",
  VehicleLookupFailure = "VehicleLookupFailure",
};

export enum PrivateHireFinalQuotePayload {
  AuthenticationRequired = "AuthenticationRequired",
  PrivateHireFinalQuoteSuccess = "PrivateHireFinalQuoteSuccess",
  PrivateHireFinalQuoteError = "PrivateHireFinalQuoteError",
};

export enum DriverBehaviourSource {
  ZEGO_TELEMATICS = "ZEGO_TELEMATICS",
  WORK_PROVIDER = "WORK_PROVIDER",
};

export enum VanFinalQuotePayload {
  AuthenticationRequired = "AuthenticationRequired",
  VanFinalQuoteSuccess = "VanFinalQuoteSuccess",
  VanFinalQuoteError = "VanFinalQuoteError",
};

export enum UpdateCrmWithPolicyFrequencyPayload {
  AuthenticationRequired = "AuthenticationRequired",
  UpdateCrmWithPolicyFrequencySuccess = "UpdateCrmWithPolicyFrequencySuccess",
  UpdateCrmWithPolicyFrequencyError = "UpdateCrmWithPolicyFrequencyError",
};

export enum RequestWebsiteLoginLinkPayload {
  RequestWebsiteLoginLinkSuccess = "RequestWebsiteLoginLinkSuccess",
  RequestWebsiteLoginLinkUserNotFound = "RequestWebsiteLoginLinkUserNotFound",
};

export enum CreateIrelandTaxiTelematicsLeadPayload {
  CreateIrelandTaxiTelematicsLeadSuccess = "CreateIrelandTaxiTelematicsLeadSuccess",
  CreateIrelandTaxiTelematicsLeadError = "CreateIrelandTaxiTelematicsLeadError",
};

export enum CreateFleetQuoteRequestLeadResponse {
  CreateFleetQuoteRequestLeadSuccess = "CreateFleetQuoteRequestLeadSuccess",
  CreateFleetQuoteRequestLeadError = "CreateFleetQuoteRequestLeadError",
};

export enum CreateOlaLeadResponse {
  CreateOlaLeadSuccess = "CreateOlaLeadSuccess",
  CreateOlaLeadError = "CreateOlaLeadError",
};

export enum SendBrokerInterestPayload {
  SendBrokerInterestError = "SendBrokerInterestError",
  SendBrokerInterestSuccess = "SendBrokerInterestSuccess",
};

export enum SendBrokerInterestFieldName {
  BROKERAGE_NAME = "BROKERAGE_NAME",
  FIRST_NAMES = "FIRST_NAMES",
  LAST_NAME = "LAST_NAME",
  EMAIL = "EMAIL",
  PHONE_NUMBER = "PHONE_NUMBER",
  FLEETS_MANAGED = "FLEETS_MANAGED",
  WORKS_WITH_FLEETS = "WORKS_WITH_FLEETS",
  ESTIMATED_TOTAL_GWP = "ESTIMATED_TOTAL_GWP",
  ESTIMATED_FLEET_GWP = "ESTIMATED_FLEET_GWP",
  ESTIMATED_LEADS_PER_MONTH = "ESTIMATED_LEADS_PER_MONTH",
  PRICING_REQUEST = "PRICING_REQUEST",
  TAXI_FLEET = "TAXI_FLEET",
  TPM_FLEET = "TPM_FLEET",
  BUSINESS_FLEET = "BUSINESS_FLEET",
  MICRO_MOBILITY_FLEET = "MICRO_MOBILITY_FLEET",
  OTHER_FLEET = "OTHER_FLEET",
  COUNTRY = "COUNTRY",
};

export enum SendFleetInterestPayload {
  SendFleetInterestError = "SendFleetInterestError",
  SendFleetInterestSuccess = "SendFleetInterestSuccess",
};

export enum SendFleetInterestFieldName {
  company = "company",
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  EMAIL = "EMAIL",
  PHONE_NUMBER = "PHONE_NUMBER",
  TERMS_AGREEMENT = "TERMS_AGREEMENT",
  COUNTRY = "COUNTRY",
};

export enum CancelPolicyPayload {
  AuthenticationRequired = "AuthenticationRequired",
  CancelProductError = "CancelProductError",
  CancelProductSuccess = "CancelProductSuccess",
};

export enum CancelSignUpPayload {
  AuthenticationRequired = "AuthenticationRequired",
  CancelSignUpError = "CancelSignUpError",
  CancelSignUpSuccess = "CancelSignUpSuccess",
};

export enum AddEmploymentPayload {
  AuthenticationRequired = "AuthenticationRequired",
  AddEmploymentError = "AddEmploymentError",
  AddEmploymentSuccess = "AddEmploymentSuccess",
};

export enum RemoveEmploymentPayload {
  AuthenticationRequired = "AuthenticationRequired",
  RemoveEmploymentError = "RemoveEmploymentError",
  RemoveEmploymentSuccess = "RemoveEmploymentSuccess",
};

export enum QuoteChangeFlexVehiclePayload {
  QuoteChangeFlexVehicleSuccess = "QuoteChangeFlexVehicleSuccess",
  QuoteChangeFlexVehicleError = "QuoteChangeFlexVehicleError",
  AuthenticationRequired = "AuthenticationRequired",
};

export enum GetFixedVehicleQuotePayload {
  AuthenticationRequired = "AuthenticationRequired",
  GetFixedVehicleMtaQuoteError = "GetFixedVehicleMtaQuoteError",
  GetFixedVehicleMtaQuoteSuccess = "GetFixedVehicleMtaQuoteSuccess",
};

export enum PaymentMethod {
  CARD = "CARD",
  MONTHLY = "MONTHLY",
};

export enum QuoteChangeFixedAddressPayload {
  QuoteChangeFixedAddressSuccess = "QuoteChangeFixedAddressSuccess",
  QuoteChangeFixedAddressError = "QuoteChangeFixedAddressError",
  AuthenticationRequired = "AuthenticationRequired",
};

export enum AcceptFixedVehicleQuotePayload {
  AuthenticationRequired = "AuthenticationRequired",
  AcceptFixedVehicleMtaQuoteError = "AcceptFixedVehicleMtaQuoteError",
  AcceptFixedVehicleMtaQuoteSuccess = "AcceptFixedVehicleMtaQuoteSuccess",
};

export enum AcceptFixedQuotePayload {
  AuthenticationRequired = "AuthenticationRequired",
  AcceptFixedMtaQuoteError = "AcceptFixedMtaQuoteError",
  AcceptFixedMtaQuoteSuccess = "AcceptFixedMtaQuoteSuccess",
};

export enum QuoteChangeFlexAddressPayload {
  QuoteChangeFlexAddressSuccess = "QuoteChangeFlexAddressSuccess",
  QuoteChangeFlexAddressError = "QuoteChangeFlexAddressError",
  AuthenticationRequired = "AuthenticationRequired",
};

export enum BindFlexMtaQuotePayload {
  BindFlexMtaQuoteSuccess = "BindFlexMtaQuoteSuccess",
  BindFlexMtaQuoteError = "BindFlexMtaQuoteError",
  AuthenticationRequired = "AuthenticationRequired",
};

export enum UpdatePersonalDetailsPayload {
  UpdatePersonalDetailsSuccess = "UpdatePersonalDetailsSuccess",
  UpdatePersonalDetailsError = "UpdatePersonalDetailsError",
  AuthenticationRequired = "AuthenticationRequired",
};

export enum GetFixedCancellationQuotePayload {
  AuthenticationRequired = "AuthenticationRequired",
  GetFixedCancellationQuoteError = "GetFixedCancellationQuoteError",
  GetFixedCancellationQuoteSuccess = "GetFixedCancellationQuoteSuccess",
};

export enum CancelFixedPolicyPayload {
  AuthenticationRequired = "AuthenticationRequired",
  CancelFixedPolicyError = "CancelFixedPolicyError",
  CancelFixedPolicySuccess = "CancelFixedPolicySuccess",
};

export enum AcceptRenewalQuotePayload {
  AuthenticationRequired = "AuthenticationRequired",
  AcceptRenewalQuoteError = "AcceptRenewalQuoteError",
  AcceptRenewalQuoteRequiresPaymentAuthorisation = "AcceptRenewalQuoteRequiresPaymentAuthorisation",
  AcceptRenewalQuoteSuccess = "AcceptRenewalQuoteSuccess",
};

export enum DeclineRenewalQuotePayload {
  AuthenticationRequired = "AuthenticationRequired",
  DeclineRenewalQuoteError = "DeclineRenewalQuoteError",
  DeclineRenewalQuoteSuccess = "DeclineRenewalQuoteSuccess",
};

export enum SkipUberPayload {
  AuthenticationRequired = "AuthenticationRequired",
  SkipUberSuccess = "SkipUberSuccess",
};

export enum UnsetChosenPaymentOptionPayload {
  AuthenticationRequired = "AuthenticationRequired",
  OnlineCheckoutProgressNotFound = "OnlineCheckoutProgressNotFound",
  InvalidCheckoutStatus = "InvalidCheckoutStatus",
  InvalidPaymentIntentStatus = "InvalidPaymentIntentStatus",
  UnsetChosenPaymentOptionSuccess = "UnsetChosenPaymentOptionSuccess",
};

export enum UpdateChosenPaymentOptionPayload {
  AuthenticationRequired = "AuthenticationRequired",
  OnlineCheckoutProgressNotFound = "OnlineCheckoutProgressNotFound",
  NoQuoteSelected = "NoQuoteSelected",
  StartDateTooSoon = "StartDateTooSoon",
  UpdateChosenPaymentOptionSuccess = "UpdateChosenPaymentOptionSuccess",
};

export enum AddPclFitsOutcomePayload {
  NoActionTaken = "NoActionTaken",
  OnlineCheckoutProgressNotFound = "OnlineCheckoutProgressNotFound",
  QuoteForCheckoutNotFound = "QuoteForCheckoutNotFound",
  InvalidPclClientReference = "InvalidPclClientReference",
  PclOutcomeBadSignature = "PclOutcomeBadSignature",
  AddPclFitsOutcomeSuccess = "AddPclFitsOutcomeSuccess",
};

export enum RedirectOutcome {
  CONFIRM = "CONFIRM",
  DECLINE = "DECLINE",
  CANCEL = "CANCEL",
  ERROR = "ERROR",
};

export enum ConfirmCancelCheckoutPayload {
  AuthenticationRequired = "AuthenticationRequired",
  OnlineCheckoutProgressNotFound = "OnlineCheckoutProgressNotFound",
  InvalidCheckoutStatus = "InvalidCheckoutStatus",
  InvalidPaymentIntentStatus = "InvalidPaymentIntentStatus",
  ConfirmCancelCheckoutSuccess = "ConfirmCancelCheckoutSuccess",
};

export enum CancelAndReEstimatePayload {
  AuthenticationRequired = "AuthenticationRequired",
  OnlineCheckoutProgressNotFound = "OnlineCheckoutProgressNotFound",
  NoUserProductForCheckout = "NoUserProductForCheckout",
  GetPrivateHirePriceEstimate2Payload = "GetPrivateHirePriceEstimate2Payload",
};

export enum AcceptFleetSubscriptionQuotePayload {
  AcceptFleetSubscriptionQuoteError = "AcceptFleetSubscriptionQuoteError",
  AcceptFleetSubscriptionQuoteSuccess = "AcceptFleetSubscriptionQuoteSuccess",
};

export enum AddFleetDriverPayload {
  AddFleetDriverError = "AddFleetDriverError",
  AddFleetDriverSuccess = "AddFleetDriverSuccess",
};

export enum AddFleetVehiclePayload {
  AddFleetVehicleInvalidInput = "AddFleetVehicleInvalidInput",
  AddFleetVehicleSuccess = "AddFleetVehicleSuccess",
};

export enum AddFleetVehicleFieldName {
  REGISTRATION_NUMBER = "REGISTRATION_NUMBER",
  MAKE = "MAKE",
  MODEL = "MODEL",
  YEAR = "YEAR",
  ENGINE_SIZE = "ENGINE_SIZE",
  FUEL_TYPE = "FUEL_TYPE",
  VEHICLE_TYPE = "VEHICLE_TYPE",
  SEATS = "SEATS",
  FLEET = "FLEET",
  GARAGE_TYPE = "GARAGE_TYPE",
  ALARM = "ALARM",
  VALUATION = "VALUATION",
  SERIAL_NUMBER = "SERIAL_NUMBER",
  COVER_STARTS_AT = "COVER_STARTS_AT",
  CITY = "CITY",
  INTEGRATION_ID = "INTEGRATION_ID",
  DEVICE_ID = "DEVICE_ID",
};

export enum AddFleetVehiclesPayload {
  AddFleetVehiclesInvalidInput = "AddFleetVehiclesInvalidInput",
  AddFleetVehiclesSuccess = "AddFleetVehiclesSuccess",
};

export enum AddOdometerReadingPayload {
  AuthenticationRequired = "AuthenticationRequired",
  AddOdometerReadingError = "AddOdometerReadingError",
  AddOdometerReadingSuccess = "AddOdometerReadingSuccess",
};

export enum BindFleetSubscriptionQuotePayload {
  BindFleetSubscriptionQuoteError = "BindFleetSubscriptionQuoteError",
  BindFleetSubscriptionQuoteSuccess = "BindFleetSubscriptionQuoteSuccess",
};

export enum CompleteFleetDriverSelfOnboardingPayload {
  CompleteFleetDriverSelfOnboardingInvalidInput = "CompleteFleetDriverSelfOnboardingInvalidInput",
  CompleteFleetDriverSelfOnboardingSuccess = "CompleteFleetDriverSelfOnboardingSuccess",
};

export enum CreateFleetDriverOnboardingSessionPayload {
  CreateFleetDriverOnboardingSessionInvalidInput = "CreateFleetDriverOnboardingSessionInvalidInput",
  CreateFleetDriverOnboardingSessionSuccess = "CreateFleetDriverOnboardingSessionSuccess",
};

export enum CreateFleetDriverOnboardingSessionFieldName {
  FLEET = "FLEET",
  EMAIL = "EMAIL",
  COUNTRY = "COUNTRY",
};

export enum FileUploadMileagePayload {
  AuthenticationRequired = "AuthenticationRequired",
  FileUploadMileageError = "FileUploadMileageError",
  FileUploadMileageSuccess = "FileUploadMileageSuccess",
};

export enum FileUploadFleetDriversPayload {
  FileUploadFleetDriversError = "FileUploadFleetDriversError",
  FileUploadFleetDriversSuccess = "FileUploadFleetDriversSuccess",
};

export enum FileUploadFleetVehiclesPayload {
  AuthenticationRequired = "AuthenticationRequired",
  FileUploadFleetVehiclesError = "FileUploadFleetVehiclesError",
  FileUploadFleetVehiclesSuccess = "FileUploadFleetVehiclesSuccess",
};

export enum FileUploadSignedFleetDocumentPayload {
  AuthenticationRequired = "AuthenticationRequired",
  FileUploadSignedFleetDocumentError = "FileUploadSignedFleetDocumentError",
  FileUploadSignedFleetDocumentSuccess = "FileUploadSignedFleetDocumentSuccess",
};

export enum RemoveFleetDriverOutput {
  AuthenticationRequired = "AuthenticationRequired",
  RemoveFleetDriverError = "RemoveFleetDriverError",
  RemoveFleetDriverSuccess = "RemoveFleetDriverSuccess",
};

export enum CreateFleetDriversOnboardingSessionFieldName {
  FLEET = "FLEET",
  EMAIL = "EMAIL",
};

export enum AddBulkFleetVehicleFieldName {
  REGISTRATION_NUMBER = "REGISTRATION_NUMBER",
  MAKE = "MAKE",
  MODEL = "MODEL",
  YEAR = "YEAR",
  ENGINE_SIZE = "ENGINE_SIZE",
  FUEL_TYPE = "FUEL_TYPE",
  VEHICLE_TYPE = "VEHICLE_TYPE",
  SEATS = "SEATS",
  GROSS_WEIGHT = "GROSS_WEIGHT",
  VIN = "VIN",
  FLEET = "FLEET",
  GARAGE_TYPE = "GARAGE_TYPE",
  ALARM = "ALARM",
  VALUATION = "VALUATION",
  SERIAL_NUMBER = "SERIAL_NUMBER",
  COVER_STARTS_AT = "COVER_STARTS_AT",
  CITY = "CITY",
  INTEGRATION_ID = "INTEGRATION_ID",
  DEVICE_ID = "DEVICE_ID",
};

export enum RequestVehicleAuthorisationPayload {
  AuthenticationRequired = "AuthenticationRequired",
  RequestVehicleAuthorisationError = "RequestVehicleAuthorisationError",
  RequestVehicleAuthorisationResult = "RequestVehicleAuthorisationResult",
};

export enum CancelVehicleAuthorisationPayload {
  AuthenticationRequired = "AuthenticationRequired",
  CancelVehicleAuthorisationError = "CancelVehicleAuthorisationError",
  CancelVehicleAuthorisationSuccess = "CancelVehicleAuthorisationSuccess",
};

export enum ConfirmVehicleAuthorisationPayload {
  AuthenticationRequired = "AuthenticationRequired",
  ConfirmVehicleAuthorisationError = "ConfirmVehicleAuthorisationError",
  ConfirmVehicleAuthorisationResult = "ConfirmVehicleAuthorisationResult",
};

export enum UpdateVehicleAuthorisationPayload {
  AuthenticationRequired = "AuthenticationRequired",
  UpdateVehicleAuthorisationError = "UpdateVehicleAuthorisationError",
  UpdateVehicleAuthorisationSuccess = "UpdateVehicleAuthorisationSuccess",
};

export enum CancelSubscriptionPayload {
  AuthenticationRequired = "AuthenticationRequired",
  CancelSubscriptionError = "CancelSubscriptionError",
  CancelSubscriptionSuccess = "CancelSubscriptionSuccess",
};

export enum CreateCheckoutSessionIdPayload {
  CreateCheckoutSessionIdPayloadSuccess = "CreateCheckoutSessionIdPayloadSuccess",
  CreateCheckoutSessionIdPayloadError = "CreateCheckoutSessionIdPayloadError",
};

export enum SelectFleetTariffPayload {
  SelectFleetTariffError = "SelectFleetTariffError",
  SelectFleetTariffSuccess = "SelectFleetTariffSuccess",
};

export enum CreateFleetCalculatorLeadPayload {
  CreateFleetCalculatorLeadError = "CreateFleetCalculatorLeadError",
  CreateFleetCalculatorLeadSuccess = "CreateFleetCalculatorLeadSuccess",
};

export enum CreateFleetCalculatorProposalPayload {
  CreateFleetCalculatorProposalError = "CreateFleetCalculatorProposalError",
  CreateFleetCalculatorProposalSuccess = "CreateFleetCalculatorProposalSuccess",
};

export enum ExcessTypeOption {
  FIRST_PARTY = "FIRST_PARTY",
  ALL_SECTIONS = "ALL_SECTIONS",
};

export enum CreateFleetCalculatorClaimSummaryPayload {
  CreateFleetCalculatorClaimSummaryError = "CreateFleetCalculatorClaimSummaryError",
  CreateFleetCalculatorClaimSummarySuccess = "CreateFleetCalculatorClaimSummarySuccess",
};

export enum CreateFleetCalculatorTariffPayload {
  CreateFleetCalculatorTariffSuccess = "CreateFleetCalculatorTariffSuccess",
  CreateFleetCalculatorTariffError = "CreateFleetCalculatorTariffError",
};

export enum IncidentPayload {
  AuthenticationRequired = "AuthenticationRequired",
  IncidentFailure = "IncidentFailure",
  IncidentSuccess = "IncidentSuccess",
};

export enum AlternateDriverPayload {
  AuthenticationRequired = "AuthenticationRequired",
  AlternateDriverFailure = "AlternateDriverFailure",
  AlternateDriverSuccess = "AlternateDriverSuccess",
};

export enum IncidentVehiclePayload {
  AuthenticationRequired = "AuthenticationRequired",
  UpdateIncidentVehicleFailure = "UpdateIncidentVehicleFailure",
  UpdateIncidentVehicleSuccess = "UpdateIncidentVehicleSuccess",
};

export enum SubmitIncidentPayload {
  AuthenticationRequired = "AuthenticationRequired",
  SubmitIncidentFailure = "SubmitIncidentFailure",
  SubmitIncidentSuccess = "SubmitIncidentSuccess",
};

export enum InvolvedVehiclePayload {
  AuthenticationRequired = "AuthenticationRequired",
  InvolvedVehicleFailure = "InvolvedVehicleFailure",
  InvolvedVehicleSuccess = "InvolvedVehicleSuccess",
};

export enum InvolvedIndividualPayload {
  AuthenticationRequired = "AuthenticationRequired",
  InvolvedIndividualFailure = "InvolvedIndividualFailure",
  InvolvedIndividualSuccess = "InvolvedIndividualSuccess",
  AddInvolvedIndividualsSuccess = "AddInvolvedIndividualsSuccess",
  UpdateInvolvedIndividualsSuccess = "UpdateInvolvedIndividualsSuccess",
};

export enum IndividualType {
  PASSENGER = "PASSENGER",
  PASSENGER_OTHER = "PASSENGER_OTHER",
  CYCLIST = "CYCLIST",
  PEDESTRIAN = "PEDESTRIAN",
  WITNESS = "WITNESS",
};

export enum RequiredAction {
  RequiredActionAcceptNewTerms = "RequiredActionAcceptNewTerms",
  RequiredActionLinkAccount = "RequiredActionLinkAccount",
};

export enum IntegrationDeviceRegisteredStatus {
  REGISTERED = "REGISTERED",
  UNREGISTERED = "UNREGISTERED",
};

