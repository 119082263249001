import getConfig from 'next/config';

import productCodeAndPaths from '@zego/content/product-code-and-path-mapping.json';

type IncludeFunction = (value: string) => boolean;

export function excludeExtensions(...exts: string[]): IncludeFunction {
  const extensions = Array.from(exts);

  return name => extensions.every(ext => !name.endsWith(ext));
}

export const withoutTemplates = (name: string): boolean =>
  ['_app', '_document', '_error'].every(template => !name.includes(template));

export const withoutExtension = (name: string): string => name.slice(0, -3);

export const withoutPagePath = (name: string): string =>
  name.replace('src/pages/', '');

export function withoutIndex(name: string): string {
  if (name.endsWith('index')) {
    return name.slice(0, -5);
  }

  return name;
}

export function withoutTrailingSlash(name: string): string {
  if (name.endsWith('/')) {
    return name.slice(0, -1);
  }

  return name;
}

export function pathWithCountry(country: string, path: string): string {
  return country !== 'gb' ? `/${country}${path}` : path;
}

export function bySpecificity(routeA: string, routeB: string): number {
  if (routeA.includes(']') && routeB.includes(']')) {
    return routeB.length - routeA.length;
  }

  if (routeA.includes(']')) return 1;

  return -1;
}

export function withBaseUrl(path: string): string {
  const { publicRuntimeConfig } = getConfig();
  return `${publicRuntimeConfig.WEBSITE_BASE_URL}/${path}`;
}

export function excludeRoutes(
  routes: Array<RegExp | string>,
): IncludeFunction {
  return path =>
    routes.every(route => {
      if (route instanceof RegExp) {
        return !path.match(route);
      }
      return !path.startsWith(route);
    });
}

export function withoutTrailingIndex(path: string): string {
  const endPart = '/index/';
  if (path.endsWith(endPart)) {
    return path.slice(0, (endPart.length - 1) * -1);
  }
  return path;
}

// Note, order is important so that it matches with the appropriate path when it has overlapping words (e.g., /fleet vs. /fleet/micromobility)
// only courier and own goods products is different for now
const productCodeAndLandingPagePathMapping =
  productCodeAndPaths.PRODUCT_CODE_AND_LANDING_PAGE_PATH_MAPPING;

// Note, order is important so that it matches with the appropriate path when it has overlapping words (e.g., /fleet vs. /fleet/micromobility)
const productCodeAndPathMapping =
  productCodeAndPaths.PRODUCT_CODE_AND_PATH_MAPPING;

export function landingPagePathFromProductCode(
  productCode: string,
): string | undefined {
  return productCodeAndLandingPagePathMapping.find(product =>
    productCode.includes(product.code),
  )?.path;
}

export function pathFromProductCode(productCode: string): string | undefined {
  return productCodeAndPathMapping.find(product =>
    productCode.includes(product.code),
  )?.path;
}

export function productCodeFromPath(path: string): string | undefined {
  return productCodeAndPathMapping.find(product => path.includes(product.path))
    ?.code;
}

export function getCountrySupportLink(country = 'gb'): string {
  const {
    publicRuntimeConfig: { DEPLOY_ENV },
  } = getConfig();
  const isGB = country === 'gb';
  if (DEPLOY_ENV === 'production' && isGB) return 'https://support.zego.com';
  if (isGB) return 'https://support.zego.tech';
  return pathWithCountry(country, '/contact-us');
}
