import { t } from '@lingui/macro';
import addDays from 'date-fns/add_days';
import isAfter from 'date-fns/is_after';
import isBefore from 'date-fns/is_before';
import getConfig from 'next/config';

import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { isValid, toNormalised } from 'postcode';
import { decode as decodeQs, encode as encodeQs } from 'qss';

import { getBaseUrlFromProductCategory } from '@zego/flows/redirects';
import { COUNTRY_MAP } from '@zego/i18n/constants';
import { productCodeFromPath } from '@zego/utils/route.ts';

import getStorage from './getStorage';

export function isBrowser() {
  return typeof window !== 'undefined';
}

export const interpolateString = (string, data) => {
  return string.replace(/\$\$|\$([a-zA-Z0-9_]*)/g, (match, key) => {
    if (match === '$$') return '$';
    if (!data[key]) throw new Error(`Missing key ${key} in format data`);
    return data[key];
  });
};

export function chunkArray(array, chunkSize) {
  const chunks = [];
  const n = array.length;
  let i = 0;

  while (i < n) {
    chunks.push(array.slice(i, (i += chunkSize)));
  }

  return chunks;
}

export function getURLParameterByName(name) {
  return new URLSearchParams(window.location.search).get(name) || '';
}

export function prettyTime(timeString) {
  const [hours, minutes] = timeString.split(':');
  return `${hours}:${minutes}`;
}

const DAYS_OF_THE_WEEK = [
  {
    key: 'monday',
    label: t('Days of week / Monday')`Monday`,
    abbrLabel: t('Days of week / Monday (short) ')`Mon`,
  },
  {
    key: 'tuesday',
    label: t('Days of week / Tuesday')`Tuesday`,
    abbrLabel: t('Days of week / Tuesday (short) ')`Tue`,
  },
  {
    key: 'wednesday',
    label: t('Days of week / Wednesday')`Wednesday`,
    abbrLabel: t('Days of week / Wednesday (short) ')`Wed`,
  },
  {
    key: 'thursday',
    label: t('Days of week / Thursday')`Thursday`,
    abbrLabel: t('Days of week / Thursday (short) ')`Thu`,
  },
  {
    key: 'friday',
    label: t('Days of week / Friday')`Friday`,
    abbrLabel: t('Days of week / Friday (short) ')`Fri`,
  },
  {
    key: 'saturday',
    label: t('Days of week / Saturday')`Saturday`,
    abbrLabel: t('Days of week / Saturday (short) ')`Sat`,
  },
  {
    key: 'sunday',
    label: t('Days of week / Sunday')`Sunday`,
    abbrLabel: t('Days of week / Sunday (short) ')`Sun`,
  },
];

function mapUtm(utmData) {
  return {
    source: utmData.source,
    medium: utmData.medium,
    campaign: utmData.name,
    term: utmData.term,
    content: utmData.content,
    adgroup: utmData.adgroup,
  };
}

export function getUtmMutationData() {
  if (!isBrowser()) {
    return null;
  }

  const storage = getStorage('session');
  return storage && storage.getItem('utmData') !== null
    ? mapUtm(storage.getItem('utmData'))
    : null;
}

export function groupOpeningHours(openingHours) {
  const weekSchedule = DAYS_OF_THE_WEEK.map(day => {
    const openingHour = openingHours[day.key];
    if (!openingHour) {
      throw new Error(`Can't find opening hours for ${day.key}`);
    }
    return {
      ...day,
      ...openingHour,
    };
  });

  // Assuming that each day only has 1 time block
  const groups = groupBy(weekSchedule, day => {
    if (day.isClosed) {
      return null;
    }
    return `${day.times[0].start}-${day.times[0].end}`;
  });

  return map(groups, group => {
    if (group[0].isClosed) {
      return {
        days: [group[0].label, group[group.length - 1].label],
        isClosed: true,
      };
    }
    return {
      days: [group[0].label, group[group.length - 1].label],
      times: [group[0].times[0].start, group[0].times[0].end],
    };
  });
}

export const isValidUKRegistrationNumber = value => {
  const cleanedValue = value.replace(/\s/g, '');
  const mibRegex = /(^[A-Z]{2}[0-9]{2}[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/gim;

  return !!cleanedValue.match(mibRegex);
};

export const isValidUKLicenceNumberFormat = value => {
  const cleanedValue = value.replace(/\s/g, '');
  const UKLicenceNumberFormatRegex = /^(?=.{16}$)[A-Za-z]{1,5}9{0,4}[0-9](?:[05][1-9]|[16][0-2])(?:[0][1-9]|[12][0-9]|3[01])[0-9](?:99|[A-Za-z][A-Za-z9])(?![IOQYZioqyz01_])\w[A-Za-z]{2}/;

  return !!cleanedValue.match(UKLicenceNumberFormatRegex);
};

export const getPolicyStartDateErrorMessage = (startDate, maxQuoteLag) => {
  const today = new Date().toDateString();
  if (isBefore(startDate, today)) {
    return 'The policy start date cannot be in the past';
  }
  const maxQuoteLagDate = addDays(today, maxQuoteLag);
  if (isAfter(startDate.toDateString(), maxQuoteLagDate)) {
    return `The policy start date has to be within ${maxQuoteLag} days of today.`;
  }
};

export function replaceDomain(url, domain) {
  const { pathname } = new URL(url);

  return `${domain}/${pathname}`;
}

export function addQueryParam(url, name, value) {
  const [path, query = ''] = url.split('?');
  const queryObj = decodeQs(query);
  queryObj[name] = value;
  return `${path}?${encodeQs(queryObj)}`;
}

export function yupToFormErrors(yupError) {
  let errors = {};
  if (yupError.inner) {
    if (yupError.inner.length === 0) {
      errors[yupError.path] = yupError.message;
      return errors;
    }
    for (let err of yupError.inner) {
      if (!errors[err.path]) {
        errors[err.path] = err.message;
      }
    }
  }
  return errors;
}

export const createUberLoginLink = (
  product,
  baseUrl,
  uberUrl,
  path,
  trackingTag,
) => {
  const successUrl = new URL(`${baseUrl}${path}`);
  successUrl.search = `?uber_login=1&product=${product}`;
  const url = new URL(uberUrl);
  url.searchParams.set('completed_url', successUrl.toString());
  url.searchParams.set('tracking_tag', trackingTag);
  return url.toString();
};

/**
 * Get a fully-qualified url using the current base url
 * @param {string} path - Site relative path
 */
export function getFullyQualifiedUrl(path) {
  const { publicRuntimeConfig } = getConfig();

  return new URL(path, publicRuntimeConfig.WEBSITE_BASE_URL).toString();
}

/**
 * Get the country code from a path
 * @param {string} path The path of the component
 * Will default to gb
 */
export function getCountryFromPath(path) {
  if (!path) return 'gb';

  const pathComponent = path.split('/').filter(Boolean)[0];
  if (!pathComponent || !Object.keys(COUNTRY_MAP).includes(pathComponent)) {
    return 'gb';
  }

  return pathComponent;
}

/**
 * Transform path with next.js param to express param
 * e.g. private-hire/estimates/[state] -> private-hire/estimates/:state
 * @param {*} route
 */
export function getRouteMatchFromDynamicPath(route) {
  return route.replace(/\[([a-zA-Z]+)\]/g, ':$1');
}

/**
 * Transform path with express param to next.js param
 * e.g. private-hire/estimates/:state -> private-hire/estimates/[state]
 * @param {*} route
 */
export function getDynamicPathFromRouteMatch(route) {
  return route.replace(/:([a-zA-Z]+)/g, '[$1]');
}

/**
 * Produce a human readable list (English) given an array of strings
 * e.g. ['a','b','c'] -> "a, b, and c"
 *
 * @param {Array<String>} list list of strings to join
 *
 * @todo replace with https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat/ListFormat if within browser support window
 */
export function getHumanizedList(list) {
  if (list.length === 1) return list.join('');

  return list.reduce((prev, curr, index, arr) => {
    if (index === arr.length - 1) return `${prev} and ${curr}`;

    // Filter out falsy and join with comma
    return [prev, curr].filter(Boolean).join(', ');
  }, '');
}

export function getBaseUrlFromPath(pathname) {
  const country = getCountryFromPath(pathname).toUpperCase();
  const product = productCodeFromPath(pathname);
  const productCategory = `${country}.${product}`;

  return getBaseUrlFromProductCategory(productCategory);
}

export function UkPostcodeValidation(string) {
  const cleanString = string.trim();
  return {
    isValid: isValid(cleanString),
    sanitisedInput: toNormalised(cleanString),
  };
}
