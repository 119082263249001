import { EstimateState } from '@zego/enums';

enum UrlState {
  REJECTED = 'rejected',
  REJECTED_WITH_LEAD = 'rejected_with_lead',
  VEHICLE_DETAILS_NEEDED = 'vehicle',
  LEAD_DETAILS_NEEDED = 'details',
  OPTION_CHOICE_NEEDED = 'options',
  USER_NEEDED = 'authenticate',
  NO_OPTIONS_AVAILABLE = 'no-options',
  SUPPORT_NEEDED = 'support-needed',
  COMPLETED = 'success',
}

export function urlStateFromApiState(
  apiState: EstimateState,
): UrlState | undefined {
  switch (apiState) {
    case EstimateState.REJECTED:
    case EstimateState.REJECTED_WITH_LEAD:
      return UrlState.REJECTED;
    case EstimateState.VEHICLE_DETAILS_NEEDED:
      return UrlState.VEHICLE_DETAILS_NEEDED;
    case EstimateState.LEAD_DETAILS_NEEDED:
      return UrlState.LEAD_DETAILS_NEEDED;
    case EstimateState.NO_OPTIONS_AVAILABLE:
      return UrlState.NO_OPTIONS_AVAILABLE;
    case EstimateState.OPTION_CHOICE_NEEDED:
      return UrlState.OPTION_CHOICE_NEEDED;
    case EstimateState.USER_NEEDED:
      return UrlState.USER_NEEDED;
    case EstimateState.SUPPORT_NEEDED:
      return UrlState.SUPPORT_NEEDED;
    case EstimateState.COMPLETED:
      return UrlState.COMPLETED;
    default:
      return undefined;
  }
}
